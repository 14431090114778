<template>
  <v-app>
    <Header />
    <Content />
  </v-app>
</template>

<script>
import Header from "@/components/core/Header";
import Content from "@/components/core/Content";
export default {
  name: "App",

  components: {
    Header,
    Content,
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap");
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
.v-application {
  font-family: "Kanit", sans-serif !important;
}
.v-application .headline {
  font-family: "Kanit", sans-serif !important;
}
.v-application .title {
  font-family: "Kanit", sans-serif !important;
}
</style>
